
  :root .light {
    --color-tone-1: #000000;
    --color-tone-2: #787c7e;
    --color-tone-3: #878a8c;
    --color-tone-4: #d3d6da;
    --color-tone-5: #edeff1;
    --color-tone-6: #f6f7f8;
    --color-tone-7: #ffffff;
    --color-nav-hover: #f4f4f4;
    --opacity-50: rgba(255,255,255,0.5);

    --yellow: #c9b458;
    --green: #6aaa64;

    --color-present: var(--yellow);
    --color-correct: var(--green);
    --color-absent: #787c7e;
    --color-background: var(--color-tone-7);

    --key-bg: var(--color-tone-4);
    --key-bg-present: var(--yellow);
    --key-bg-correct: var(--green);
    --key-bg-absent: var(--color-absent);
    --key-text-color: var(--color-tone-1);
    --key-evaluated-text-color: var(--color-tone-7);

    --tile-text-color: var(--color-tone-7);
    background-color: var(--color-background);
  }

  :root .dark {
    --color-tone-1: #ffffff;
    --color-tone-2: #818384;
    --color-tone-3: #565758;
    --color-tone-4: #3a3a3c;
    --color-tone-5: #272729;
    --color-tone-6: #1a1a1b;
    --color-tone-7: #121213;
    --color-nav-hover: #2f2f31;
    --opacity-50: rgba(0,0,0,0.5);

    --yellow: #c9b458;
    --darkendYellow: #b59f3b;
    --green: #6aaa64;
    --darkendGreen: #538d4e;

    --color-present: var(--darkendYellow);
    --color-correct: var(--darkendGreen);
    --color-absent: var(--color-tone-4);
    --color-background: var(--color-tone-7);

    --key-bg: var(--color-tone-2);
    --key-bg-present: var(--color-present);
    --key-bg-correct: var(--color-correct);
    --key-bg-absent: var(--color-absent);
    --key-text-color: var(--color-tone-1);
    --key-evaluated-text-color: var(--color-tone-1);

    --tile-text-color: var(--color-tone-1);
    background-color: var(--color-background);
  }