

.container {
  display: flex;
  justify-content: space-between;
}

.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 22px;
  }
  
.switch input { 
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    left: 2px;
    top: 2px;
    height: calc(100% - 4px);
    /* width: 50%; */
    width: calc((100% / 2) - 2px);
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: var(--color-correct);;
}

input:focus + .slider {
    box-shadow: 0 0 1px var(--color-correct);;
}

input:checked + .slider:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 900px;
}

.slider.round:before {
    border-radius: 50%;
}
