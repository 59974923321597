
.wordy__tile_base {
    width: 100%;
    /* height: 62px; */
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    font-weight: bold;
    vertical-align: middle;
    box-sizing: border-box;
    text-transform: uppercase;
    user-select: none;
}

.wordy__tile_tbd {
    background-color: var(--color-tone-7);
    border: 2px solid var(--color-tone-3);
    color: var(--color-tone-1);
}

.wordy__tile_present {
    background-color: var(--color-present);
    border: 2px solid var(--color-present);
    color: var(--tile-text-color);
}

.wordy__tile_correct {
    background-color: var(--color-correct);
    border: 2px solid var(--color-correct);
    color: var(--tile-text-color);
}

.wordy__tile_absent {
    background-color: var(--color-absent);
    border: 2px solid var(--color-absent);
    color: var(--tile-text-color);
}

.wordy__tile_empty {
    background-color: var(--color-tone-7);
    border: 2px solid var(--color-tone-4);
    color: var(--color-tone-1);
}
