.nav_overlay {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    justify-content: left;
    align-items: left;
    background-color: transparent;
    animation: SlideIn 100ms linear;
    z-index: 3000;
}


.nav_content {
    position: relative;
    border: 1px solid var(--color-tone-6);
    color: var(--color-tone-1);
    background-color: var(--color-background);
    overflow-y: auto;
    animation: SlideRight 200ms;
    width: 100%;
    overflow-y: auto;
    max-height: calc(100% - var(--header-height) - 1px);
    margin-top: calc(var(--header-height) + 1px);
    padding: 0px
}

@media screen and (min-width: 415px) {
    .nav_content {
        width: 375px;
    }
}

.temp {
    margin-left: 15px;
    font-weight: bold;
}