.wordy__alert {
    height: 40px;
    min-width: 175px;
    padding: 5px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    font-weight: bold;
    vertical-align: middle;
    box-sizing: border-box;
    user-select: none;
}

.wordy__alert_info {
    background-color: var(--color-tone-1);
    border: 2px solid var(--color-tone-1);
    color: var(--color-tone-7);
}