
.wordy__game {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    height: calc(100% - var(--header-height));;
    display: flex;
    flex-direction: column;
}

.wordy__board_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    overflow: hidden;
}

.wordy__alert_container {
    position: absolute;
    left: 0; top: var(--header-height); right: 0; bottom: var(--keyboard-height);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    overflow: hidden;
    z-index: 100;
}