.settings_overlay {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    justify-content: center;
    background-color: var(--color-background);
    animation: SlideIn 100ms linear;
    z-index: 2000;
}

.settings_content {
    position: relative;
    color: var(--color-tone-1);
    margin: 0 auto;
    max-width: var(--game-max-width);
    overflow-y: auto;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}