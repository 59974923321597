
.setting {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--color-tone-4);
    padding: 16px 16px;
}

.text {
    padding-right: 8px;
}

.title {
    font-size: 18px;
    font-weight: bold;
}

.description {
    font-size: 12px;
    color: var(--color-tone-2);
}