.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding: 0px 16px;
    height: var(--header-height);
    color: var(--color-tone-1);
    border-bottom: 1px solid var(--color-tone-4);
}

.header_title {
    /* font-family: 'Times New Roman'; */
    font-weight: 900;
    font-size: 30px;
    line-height: 100%;
    letter-spacing: 0.01em;
    text-align: center;
    left: 0;
    right: 0;
    pointer-events: none;
}

.header_left {
    display: flex;
    margin: 0;
    padding: 0;
    align-items: center;
    width: 70px;
    justify-content: flex-start;
}

.header_right {
    display: flex;
    width: 70px;
    justify-content: flex-end;
}

.button_icon {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0 4px;
    color: var(--color-tone-1);
}

.icon {
    height: 24px;
    width: 24px;
    padding-top: 4px;
}