.wordy__keyboard {
    height: var(--keyboard-height);
    margin: 0 8px;
    user-select: none;
    display: block;
}

.wordy__key-row {
    display: flex;
    width: 100%;
    margin: 0 auto 8px;
    touch-action: manipulation;
}

.wordy__spacer-half {
    flex: 0.5;
}
