.wordy__key {
    font-family: inherit;
    font-weight: bold;
    border: 0;
    padding: 0;
    margin: 0 6px 0 0;
    height: 58px;
    border-radius: 4px;
    cursor: pointer;
    user-select: none;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    -webkit-tap-highlight-color: rgba(0,0,0,0.3);
}

.wordy__key_one_and_a_half {
    font-family: inherit;
    font-weight: bold;
    border: 0;
    padding: 0;
    margin: 0 6px 0 0;
    height: 58px;
    border-radius: 4px;
    background-color: var(--key-bg);
    color: var(--key-text-color);
    cursor: pointer;
    user-select: none;
    flex: 1.5;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    -webkit-tap-highlight-color: rgba(0,0,0,0.3);
}

.wordy__key_empty {
    background-color: var(--key-bg);
    color: var(--key-text-color);
}

.wordy__key_tbd {
    background-color: var(--key-bg);
    color: var(--key-text-color);
}

.wordy__key_correct {
    background-color: var(--key-bg-correct);
    color: var(--key-evaluated-text-color);
}

.wordy__key_present {
    background-color: var(--key-bg-present);
    color: var(--key-evaluated-text-color);
}

.wordy__key_absent {
    background-color: var(--key-bg-absent);
    color: var(--key-evaluated-text-color);
}